
import { colorUtils } from "o365-utils";

interface IColor {
    background: string;
    color: string;
    border?: string;
}

const colors: { [key: string]: IColor } = {
    /* bootstrap colors */
    "success"       : {background: "#22c55e"            , color: "white"  },
    "danger"        : {background: "#f87171"            , color: "white"  },
    "warning"       : {background: "#facc15"            , color: "white"  },
    "muted"         : {background: "#d6d3d1"            , color: "black"  },
    "primary"       : {background: "steelblue"          , color: "white"  , border: "steelblue"},
    "primary-subtle": {background: "#d6e3f0"            , color: "#4682b4", border: "#4682b4"  },
    "dark"          : {background: "#373e45"            , color: "white"  },
    "info"          : {background: "#0dcaf0"            , color: "black"  },
    "light"         : {background: "#e9ecef"            , color: "black"  },
    "secondary"     : {background: "#6c757d"            , color: "white"  },

    /* custom colors */
    "closed"        : {background: "var(--bs-gray-500)" , color: "white"  },
    "deleted"       : {background: "var(--bs-gray-500)" , color: "white"  },
    "evaluated"     : {background: "#9DBFAF"            , color: "white"  },
    "medium"        : {background: "#fde68a"            , color: "black"  },
    "high"          : {background: "#eab308"            , color: "white"  },

    // deprecated
    "orange"        : {background: "#f18A00"            , color: "white"  },
    "veryhigh"      : {background: "#f18A00"            , color: "white"  },
};

// override hex colors with predefined colors
const colorOverride: { [key: string]: keyof typeof colors } = {
    "#6de436": "success",
    "#ff3232": "danger",
    "#f2d43a": "warning",
    "#ffff00": "high",
    "#fff8dc": "medium",
    "#d3d3d3": "muted",
    "#90ee90": "success",
    "#ff0000": "danger",
};

function isHex(hex: string): boolean {
    return !!hex.match(/^#[0-9a-fA-F]{6}$/);
}

export function getColor(str: string): IColor {
    str = str.toLowerCase();

    // check if we have exact match from the color list
    let color: IColor = colors[str];
    if (color) {
        return color;
    }

    // convert css color name to hex and check overrides
    const hex = colorUtils.nameToHex(str) || str;
    const name = colorOverride[hex];
    if (name) {
        return colors[name];
    }

    // if valid hex, return hex as background and calculate text color
    if (isHex(hex)) {
        return {
            color: colorUtils.textColorBasedOnBackground(hex),
            background: hex,
        };
    }

    console.warn(`invalid color: ${str}`);

    // fallback
    return {
        color: "green",
        background: "purple",
    }
}

export default colors;
